import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { AssignmentService } from '@features/assignment/assignment.service';
import { DrillService } from '@features/drill/drill.service';
import { TranslocoDirective } from '@jsverse/transloco';
import { TypographyDirective } from '@shared/directives/typography.directive';
import { CoachService } from '@shared/services/coach.service';
import { ProgressCard, ProgressCardComponent } from '@shared/ui/progress-card/progress-card.component';

type FeatureStatus = 'done' | 'doing' | 'backlog';
type Feature = { title: string; complete?: boolean } & Partial<Record<FeatureStatus, string[]>>;

@Component({
  selector: 'coach-dashboard',
  host: { class: 'content-wrapper' },
  imports: [TranslocoDirective, TypographyDirective, ProgressCardComponent],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent {
  players = inject(CoachService).players;
  drills = inject(DrillService).drills;
  assignments = inject(AssignmentService).assignments;

  progress = computed<ProgressCard[]>(() => [
    {
      title: 'players.title',
      icon: 'player_card',
      navigation: '/players',
      progress: `${this.players.value()?.length}`,
      loading: this.players.isLoading(),
    },
    {
      title: 'drills.title',
      icon: 'coach_drill',
      navigation: '/drills',
      progress: `${this.drills.value()?.length}`,
      loading: this.drills.isLoading(),
    },
    {
      title: 'assignments.title',
      icon: 'coach_assignments',
      navigation: '/assignments',
      progress: `${this.assignments.value()?.length}`,
      loading: this.assignments.isLoading(),
    },
  ]);

  featureStatus: FeatureStatus[] = ['done', 'doing', 'backlog'];

  features: Feature[] = [
    { title: 'Settings', done: ['Profile', 'Language', 'Units', 'Cookies'], doing: [], backlog: ['Notifications'] },
    { title: 'Auth', done: ['Login', 'Logout'], doing: [], backlog: [], complete: true },
    { title: 'Player', done: ['Profile'], doing: [], backlog: ['Drills', 'Assignments'] },
    { title: 'Coach', done: ['Players', 'Invites'], doing: [], backlog: ['Drills', 'Assignments'] },
    { title: 'Game modes', done: [], doing: ['My Practice'], backlog: ['Warmup', 'T12', 'T30'] },
    { title: 'Graphs', done: ['Dispersion', 'Ball flight'], doing: [], backlog: [] },
    {
      title: 'Drills',
      done: ['View drills', 'Create drill'],
      doing: ['Better drill creation'],
      backlog: ['Using drills'],
    },
    {
      title: 'Assignments',
      done: [],
      doing: [],
      backlog: ['View assignments', 'Create assignment', 'Using assignments'],
    },
  ];
}
