<nav class="sidebar" role="navigation">
  @if (utility.navigation.isOverlay()) {
    @if (utility.navigation.toggled()) {
      <div class="focus-trap" cdkTrapFocus cdkTrapFocusAutoCapture>
        <div class="close-container">
          <coach-button (click)="utility.toggleNavigation()" variant="text" color="white" icon="x" />
        </div>
        <coach-tabs [tabs]="tabs()" (clicked)="utility.toggleNavigation()" vertical />
        <img class="logo logo-text" src="assets/images/coach-logo-primary.svg" alt="Coach logo" />
      </div>
    }
  } @else {
    <coach-tabs [tabs]="tabs()" vertical square />
    <img class="logo logo-icon" src="assets/images/coach-logo.svg" alt="Coach logo" />
  }
</nav>
