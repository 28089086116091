import { CdkTrapFocus } from '@angular/cdk/a11y';
import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { AuthService } from '@auth/services/auth.service';
import { environment } from '@env/environment';
import { UtilityService } from '@shared/services/utility.service';
import { ButtonComponent } from '@shared/ui/button/button.component';
import { Tab } from '@shared/ui/tabs/tab/tab.component';
import { TabsComponent } from '@shared/ui/tabs/tabs.component';

@Component({
  selector: 'coach-navigation',
  imports: [TabsComponent, ButtonComponent, CdkTrapFocus],
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.scss',
  host: {
    '[class.mobile]': 'utility.navigation.isOverlay()',
    '[class.expanded]': 'utility.navigation.toggled()',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent {
  #auth = inject(AuthService);
  utility = inject(UtilityService);

  tabs = computed<Tab[]>(() => [
    { id: 'dashboard', path: 'dashboard', title: 'navigation.home', icon: 'house_outlined' },
    { id: 'players', path: 'players', title: 'navigation.player', icon: 'profile_rounded' },
    { id: 'assignment', path: 'assignments', title: 'navigation.assignment', icon: 'coach_assignments' },
    { id: 'drill', path: 'drills', title: 'navigation.drill', icon: 'coach_custom_drill' },
    { id: 'admin', path: 'admin', title: 'navigation.admin', icon: 'lock', visible: this.#auth.visibleToAdmin() },
    { id: 'museum', path: 'museum', title: 'Museum', icon: 'cookie', visible: !environment.production },
  ]);
}
