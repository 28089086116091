import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { LanguageService } from '@shared/services/language.service';
import { ButtonComponent } from '../button/button.component';
import { ClickMenuConfiguration, ClickMenuItem } from '../click-menu/click-menu.types';
import { DropdownComponent } from '../dropdown/dropdown.component';

@Component({
  selector: 'coach-language-picker',
  imports: [DropdownComponent, ButtonComponent],
  templateUrl: './language-picker.component.html',
  styleUrl: './language-picker.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguagePickerComponent {
  #language = inject(LanguageService);
  menuConfig = input<ClickMenuConfiguration>({});

  menuItems: ClickMenuItem[] = this.#language.languages().map(language => ({
    id: language.code,
    title: language.name,
    subtitle: language.nativeName,
    image: `assets/images/flags/${language.flag}.svg`,
    action: () => this.setActiveLanguage(language.code),
  }));
  activeMenuItem = computed(
    () => this.menuItems.find(item => item.id === this.#language.activeLanguage().code) || this.menuItems[0]
  );

  setActiveLanguage(language: string) {
    this.#language.setActiveLanguage(language);
  }
}
