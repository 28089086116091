import { provideTransloco } from '@jsverse/transloco';
import { provideTranslocoMessageformat } from '@jsverse/transloco-messageformat';
import { provideTranslocoPersistLang } from '@jsverse/transloco-persist-lang';
import { TranslocoHttpLoader } from '@shared/services/transloco.loader';

export function provideI18n(isDevMode?: boolean) {
  const availableLangs = isDevMode ? ['en', 'sv', 'ja'] : ['en'];
  const defaultLang = 'en';
  const fallbackLang = 'en';

  return [
    provideTransloco({
      config: {
        availableLangs,
        defaultLang,
        fallbackLang,
        reRenderOnLangChange: true,
        prodMode: !isDevMode,
        missingHandler: {
          useFallbackTranslation: true,
        },
      },
      loader: TranslocoHttpLoader,
    }),
    provideTranslocoPersistLang({
      storage: {
        useValue: localStorage,
      },
    }),
    provideTranslocoMessageformat(),
  ];
}
