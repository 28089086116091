import { HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service';

/**
 * The comments in this file are here for getting to the bottom of some auth QOL fixes.
 */

/* eslint-disable no-console */

export function authInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn) {
  const auth = inject(AuthService);
  const token = auth.currentUser()?.access_token;
  const expired = auth.currentUser()?.expired;

  // Only add the Authorization header to URLs that match this pattern
  const endpoints = /^(http|https):\/\/(dev|test)\.game-data\.toptracer\.com\//;

  // I think all auth requests go through the iframe, so we can't intercept them like this
  const authEndpoints = /^(http|https):\/\/(dev|test)\.login\.toptracer\.com.*/;

  console.debug('Interceptor: ', req.method, req.url);

  if (authEndpoints.test(req.url)) {
    console.debug('Interceptor: Auth request. Expired: ', expired);
  }

  if (!token || !endpoints.test(req.url)) {
    return next(req);
  }

  if (expired) {
    console.debug('Interceptor: Token expired, attempting silent signin');
    auth.signinSilent();
  }

  return next(req.clone({ setHeaders: { Authorization: `Bearer ${token}` } }));
}
