<h1 *transloco="let t; read: 'navigation'" typography="title" variant="2">{{ t('home') }}</h1>

<div class="progress-container" *transloco="let t">
  @for (card of progress(); track card.title) {
    <coach-progress-card
      [title]="card.title"
      [icon]="card.icon"
      [progress]="card.progress"
      [navigation]="card.navigation"
      [loading]="card.loading" />
  }
</div>

<section class="introduction">
  <h2>Welcome to the New and Improved Coach Experience (NICE)</h2>
  <span>This is currently a work in progress, you can see the current progress in the lists below.</span>
  <section class="grid">
    @for (feature of features; track feature.title) {
      <section class="list" [class.complete]="feature.complete">
        <h3>{{ feature.title }} {{ feature.complete && '✓' }}</h3>
        @for (status of featureStatus; track status) {
          @if (feature[status]?.length) {
            <section>
              <span class="status">{{ status }}</span>
              <ul>
                @for (s of feature[status]; track s) {
                  <li>{{ s }}</li>
                }
              </ul>
            </section>
          }
        }
      </section>
    }
  </section>
</section>
