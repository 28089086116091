import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { ApiService } from '@shared/services/api.service';
import { AuthService } from '../services/auth.service';

export const inviteGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const auth = inject(AuthService);
  const api = inject(ApiService);

  if (auth.isLoggedIn()) {
    const invToken = route.paramMap.get('token');
    if (invToken?.length) {
      api.post(`users/invites/${invToken}`).subscribe({
        next: async () => {
          await auth.signinSilent();
          router.navigate(['/dashboard']);
          return false;
        },
        error: () => {
          if (auth.roles && auth.roles.length) {
            router.navigate(['/dashboard'], { queryParams: { error: 'Invalid token' } });
            return false;
          } else {
            auth.logOut(state.url, { queryParams: { error: 'Invalid token' } });
            return false;
          }
        },
        complete: () => {
          return false;
        },
      });
    }
    return false;
  } else {
    return true;
  }
};
