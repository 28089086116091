import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth/services/auth.service';
import { ButtonColor, ButtonComponent } from '../button/button.component';
import { ClickMenuItemGroup } from '../click-menu/click-menu.types';
import { DropdownComponent } from '../dropdown/dropdown.component';

@Component({
  selector: 'coach-profile-dropdown',
  imports: [ButtonComponent, DropdownComponent],
  template: `
    <coach-dropdown [groupedMenuItems]="items">
      <coach-button [avatar]="name()" [color]="color()" icon="chevron_down" />
    </coach-dropdown>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileDropdownComponent {
  #auth = inject(AuthService);
  #router = inject(Router);

  color = input<ButtonColor>('white');

  name = computed(() => this.#auth.currentUser()?.profile?.name);

  items: ClickMenuItemGroup[] = [
    {
      id: 'settings',
      items: [
        {
          id: 'settings',
          title: 'navigation.settings',
          action: () => this.#router.navigate(['/settings']),
        },
        {
          id: 'language',
          title: 'navigation.language',
          action: () => this.#router.navigate(['/settings/language']),
        },
      ],
    },
    {
      id: 'support',
      title: 'navigation.support',
      items: [
        {
          id: 'contact-support',
          title: 'navigation.contactSupport',
          action: () => this.#router.navigate(['/support']),
        },
        {
          id: 'manual',
          title: 'navigation.manual',
        },
        {
          id: 'give-feedback',
          title: 'navigation.giveFeedback',
          icon: 'external_link',
          iconSize: 'xs',
          iconPosition: 'right',
        },
      ],
    },
    {
      id: 'legal',
      title: 'navigation.legal',
      items: [
        {
          id: 'privacy-policy',
          title: 'navigation.privacyPolicy',
        },
        {
          id: 'terms-and-conditions',
          title: 'navigation.termsAndConditions',
        },
      ],
    },
    {
      id: 'logout',
      items: [
        {
          id: 'logout',
          title: 'auth.logout',
          icon: 'logout',
          action: () => this.#auth.logOut(),
        },
      ],
    },
  ];
}
