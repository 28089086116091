import { ChangeDetectionStrategy, Component, inject, input, signal } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth/services/auth.service';
import { Role } from '@shared/types/generic.types';
import { ButtonColor, ButtonComponent } from '@shared/ui/button/button.component';

@Component({
  selector: 'coach-role-switcher',
  imports: [ButtonComponent],
  templateUrl: './role-switcher.component.html',
  styleUrl: './role-switcher.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoleSwitcherComponent {
  auth = inject(AuthService);
  #router = inject(Router);
  color = input<ButtonColor>('black');

  roles: Role[] = ['admin', 'coach', 'player'];

  toggle = signal(false);

  viewAs(role: Role | null) {
    localStorage.setItem('viewAs', role ?? '');
    this.toggle.set(false);
    this.auth.viewAsRole.set(role);
    this.#router.navigate(['/']);
  }
}
