import { HttpErrorResponse, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { ToastService } from '@shared/services/toast.service';
import { catchError, throwError } from 'rxjs';

export function errorInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn) {
  const toast = inject(ToastService);

  return next(req).pipe(
    // TODO: Enable retries for _our_ API endpoints.
    // retry({
    //   count: 2,
    //   delay: (_, count) => timer(count * 1000),
    // }),
    catchError((error: HttpErrorResponse | Error) => {
      // TODO: We should only show toasts for our API endpoints to avoid spamming the user with potential Keycloak errors.
      if (error instanceof HttpErrorResponse) {
        toast.negative(`${error.status}: ${error.statusText}`, error.error.detail ?? error.message, false);
      } else {
        toast.negative('Intercepted error', error.message, false);
      }
      return throwError(() => error);
    })
  );
}
